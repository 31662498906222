<template>
  <modal-wrapper :id="id" noAutoClose size="md" :on-modal-show="onShowModal">
    <template #title>
      Selezione Ateco
    </template>
    <template #body>
        <b-row>
          <b-col md="12">
            <select-key-value
              :options="searchResult"
              :search-function="fetchOptions"
              v-model="atecoCode"
              v-on:option:selected="loadDetail"
              v-on:option:description="setDescription"
              placeholder="Cerca ATECO per codice o descrizione"
              title="Ricerca per codice"
            />
          </b-col>
          <b-col md="12">
            <select-key-value
              :options="sectionOptions"
              v-model="section"
              v-on:option:selected="loadDivision"
              v-on:option:description="setDescription"
              title="Sezione"
            ></select-key-value>
          </b-col>
          <b-col md="12">
            <select-key-value
              :options="divisionOptions"
              v-model="division"
              v-on:option:selected="loadGroup"
              v-on:option:description="setDescription"
              title="Divisione"
            />
          </b-col>
          <b-col md="12">
            <select-key-value
              :options="groupingOptions"
              v-model="grouping"
              v-on:option:selected="loadClass"
              v-on:option:description="setDescription"
              title="Gruppo"
            />
          </b-col>
          <b-col md="12">
            <select-key-value
              :options="classifyOptions"
              v-model="classify"
              v-on:option:selected="loadCategory"
              v-on:option:description="setDescription"
              title="Classe"
            />
          </b-col>
          <b-col md="12">
            <select-key-value
              :options="categoryOptions"
              v-model="category"
              v-on:option:selected="loadSubCategory"
              v-on:option:description="setDescription"
              title="Categoria"
            />
          </b-col>
          <b-col md="12">
            <select-key-value
              :options="subcategoryOptions"
              v-model="subcategory"
              v-on:option:description="setDescription"
              title="Sottocategoria"
            />
          </b-col>
        </b-row>
    </template>
    <template #footer>
      <div class="d-flex w-100">
        <b-button
          @click="closeModal"
          variant="primary"
          class="me-auto"
          size="sm"
        >
          Chiudi
        </b-button>
        <b-button
          :disabled="!canAcceptAteco"
          @click="setAteco"
          variant="success"
          size="sm"
        >
          Conferma
        </b-button>
      </div>
    </template>
  </modal-wrapper>
</template>

<script>
import { isArrayNotEmpty, isNotEmpty, isPresent } from '@/utils/validators';

const ModalWrapper = () => import('@/components/helpers/ModalWrapper.vue');
const SelectKeyValue = () => import('@/components/helpers/SelectKeyValue.vue');

export default {
  name: 'ModalAteco',
  props: {
    id: String,
    assignAteco: Function,
  },
  components: { SelectKeyValue, ModalWrapper },
  data() {
    return {
      atecoCode: null,
      section: null,
      division: null,
      grouping: null,
      classify: null,
      category: null,
      subcategory: null,
      description: null,
    };
  },
  created() {
    if (!isArrayNotEmpty(this.$store.getters['tableUtils/getAtecoHeaders'])) {
      if (!this.$store.getters['tableUtils/isLoadingAteco']) {
        this.$store.dispatch('tableUtils/loadAtecoHeaders');
      }
    }
  },
  computed: {
    searchResult() {
      return this.$store.getters['tableUtils/getAtecoSearchResult'];
    },
    sectionOptions() {
      return this.$store.getters['tableUtils/getAtecoHeaders'];
    },
    divisionOptions() {
      if (isPresent(this.section)) {
        return this.$store.getters['tableUtils/getAtecoSection'](this.section);
      }
      return [];
    },
    groupingOptions() {
      if (isPresent(this.division)) {
        return this.$store.getters['tableUtils/getAtecoSection'](this.division);
      }
      return [];
    },
    classifyOptions() {
      if (isPresent(this.grouping)) {
        return this.$store.getters['tableUtils/getAtecoSection'](this.grouping);
      }
      return [];
    },
    categoryOptions() {
      if (isPresent(this.classify)) {
        return this.$store.getters['tableUtils/getAtecoSection'](this.classify);
      }
      return [];
    },
    subcategoryOptions() {
      if (isPresent(this.category)) {
        return this.$store.getters['tableUtils/getAtecoSection'](this.category);
      }
      return [];
    },
    canAcceptAteco() {
      return (isPresent(this.section) && isPresent(this.division) && isPresent(this.grouping) && isPresent(this.classify));
    },
  },
  // mounted() {
  //   this.$store.dispatch('tableUtils/clearAteco');
  //   this.section = null;
  //   this.division = null;
  //   this.grouping = null;
  //   this.classify = null;
  //   this.category = null;
  //   this.subcategory = null;
  //   this.description = null;
  //
  //   const company = this.$store.getters['subject/currentCompany'];
  //   // console.log('company', company);
  //   if (isNotEmpty(company)) {
  //     const { attribute } = company;
  //     if (isPresent(attribute.atecoCode)) {
  //       this.loadDetail(attribute.atecoCode);
  //     }
  //   }
  // },
  methods: {
    onShowModal() {
      this.$store.dispatch('tableUtils/clearAteco');
      this.section = null;
      this.division = null;
      this.grouping = null;
      this.classify = null;
      this.category = null;
      this.subcategory = null;
      this.description = null;

      const company = this.$store.getters['subject/currentCompany'];
      // console.log('company', company);
      if (isNotEmpty(company)) {
        console.log('onShowModal', company);
        const { attribute } = company;
        if (isPresent(attribute.atecoCode)) {
          this.loadDetail(attribute.atecoCode);
        }
      }
    },
    closeModal() {
      this.$bvModal.hide(this.id);
    },
    // eslint-disable-next-line no-unused-vars
    fetchOptions(search, loading) {
      if (isPresent(search)) {
        this.$store.dispatch('tableUtils/searchAteco', search);
      // } else {
      //   this.$store.dispatch('tableUtils/clearAteco');
      }
    },
    setDescription(value) {
      this.description = value;
    },
    loadDetail(value) {
      // console.log('loadDetail', value);
      if (isPresent(value)) {
        this.$store.dispatch('tableUtils/decodeAteco', value).then(
          (decodeResponse) => {
            console.log('decodeAteco', decodeResponse);
            this.section = decodeResponse.section;
            this.division = decodeResponse.division;
            this.grouping = decodeResponse.grouping;
            this.classify = decodeResponse.classify;
            this.category = decodeResponse.category;
            this.subcategory = decodeResponse.subcategory;
            this.description = decodeResponse.description;
            this.loadAtecoSection(this.section);
            this.loadAtecoSection(this.division);
            this.loadAtecoSection(this.grouping);
            this.loadAtecoSection(this.classify);
            this.loadAtecoSection(this.category);
          },
          (errorResponse) => {
            console.log('decodeAtecoError', errorResponse);
            this.section = null;
            this.division = null;
            this.grouping = null;
            this.classify = null;
            this.category = null;
            this.subcategory = null;
            this.description = null;
          },
        );
      }
    },
    loadAtecoSection(value) {
      if (isPresent(value)) {
        this.$store.dispatch('tableUtils/loadAtecoSection', value).then(
          (decodeResponse) => {
            console.log('loadAtecoSection', decodeResponse);
          },
          (errorResponse) => {
            console.log('loadAtecoSection', errorResponse);
          },
        );
      }
    },
    loadDivision(value) {
      this.division = null;
      this.grouping = null;
      this.classify = null;
      this.category = null;
      this.subcategory = null;

      this.loadAtecoSection(value);
    },
    loadGroup(value) {
      this.grouping = null;
      this.classify = null;
      this.category = null;
      this.subcategory = null;

      this.loadAtecoSection(value);
    },
    loadClass(value) {
      this.classify = null;
      this.category = null;
      this.subcategory = null;

      this.loadAtecoSection(value);
    },
    loadCategory(value) {
      this.category = null;
      this.subcategory = null;

      this.loadAtecoSection(value);
    },
    loadSubCategory(value) {
      this.subcategory = null;

      this.loadAtecoSection(value);
    },
    setAteco() {
      if (this.assignAteco) {
        this.assignAteco({
          section: this.section,
          division: this.division,
          grouping: this.grouping,
          classify: this.classify,
          category: this.category,
          subcategory: this.subcategory,
          description: this.description,
        });
      }
      // this.$store.dispatch('subject/setCompanyAteco', {
      //   section: this.section,
      //   division: this.division,
      //   grouping: this.grouping,
      //   classify: this.classify,
      //   category: this.category,
      //   subcategory: this.subcategory,
      //   description: this.description,
      // });
      this.closeModal();
    },
  },
};
</script>

<style scoped>

</style>
